import { cva } from "class-variance-authority";

export const buttonVariants = cva(
  `font-sans font-medium transition-colors text-sm rounded-md inline-flex justify-center items-center whitespace-nowrap`,
  {
    variants: {
      variant: {
        overlay: `bg-black/60 text-white hover:bg-black/75 backdrop-blur`,
        special: `bg-black text-white`,
        primary: `bg-primary text-white hover:bg-primary/90`,
        secondary: `bg-secondary text-secondary-foreground hover:bg-muted-foreground/20`,
        outline: `bg-transparent text-foreground border border-muted-foreground/20 hover:bg-secondary/70`,
        ghost: "hover:bg-secondary",
        text: `bg-transparent text-content hover:text-contrast backdrop-blur`,
        warning: `bg-warning text-white hover:bg-warning/90`,
        success: `bg-success text-white hover:bg-success/90`,
        grouped: `bg-transparent text-content hover:text-contrast hover:bg-content/5`,
        transparent: `bg-transparent text-content hover:text-contrast`,
        editor: `bg-transparent text-content hover:text-contrast hover:bg-content/5 border shadow-sm`,
      },
      size: {
        xs: `h-6 px-1.5 space-x-1 text-xs rounded-md`,
        sm: `h-8 px-3 space-x-2 rounded-md`,
        md: `h-9 px-4 space-x-3`,
        lg: `h-12 px-4 space-x-4 font-semibold`,
        xl: `h-14 px-5 space-x-5 text-lg`,
        icon: "size-9",
        editor: "size-7",
      },
      disabled: {
        true: `cursor-not-allowed opacity-50`,
      },
      iconOnly: {
        true: `justify-center px-0`,
        false: ``,
      },
      rounded: {
        true: `rounded-full`,
      },
      active: {
        true: `active:bg-secondary/70`,
      },
    },

    compoundVariants: [
      { size: "xs", iconOnly: true, class: `h-6 w-6` },
      { size: "sm", iconOnly: true, class: `h-8 w-8` },
      { size: "md", iconOnly: true, class: `h-10 w-10` },
      { size: "lg", iconOnly: true, class: `h-12 w-12` },
      {
        variant: "editor",
        active: true,
        class: `border bg-secondary text-secondary-foreground hover:bg-muted-foreground/20`,
      },
    ],

    defaultVariants: {
      variant: "primary",
      size: "md",
      iconOnly: false,
    },
  },
);
