import React from "react";
import { Slot } from "@radix-ui/react-slot";
import { buttonVariants } from "./button.styles";
import { VariantProps } from "class-variance-authority";
import { cn } from "../utils";

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "disabled">,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  active?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild, className, active, variant, size, rounded, iconOnly, disabled, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    const classes = cn(buttonVariants({ variant, size, iconOnly, rounded, disabled, active, className }));
    return <Comp ref={ref} className={classes} {...props} disabled={disabled ?? false} />;
  },
);

Button.displayName = "Button";
